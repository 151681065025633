/**
 * Media query mixin for a min-width, single parameter
 * @param {String} $viewport The viewport size to min-width to
 */
 @mixin screen-from($viewport) {
  @if $viewport == mobile {
    @media screen and (min-width: 0) { @content; }
  } @else if $viewport == mobile-up {
    @media screen and (min-width: 374px) { @content; }
  } @else if $viewport == mobile-max {
    @media screen and (min-width: 413px) { @content; }
  } @else if $viewport == tablet {
    @media screen and (min-width: 767px) { @content; }
  } @else if $viewport == desktop {
    @media screen and (min-width: 1023px) { @content; }
  } @else if $viewport == landscape {
    @media screen and (max-width: 900px) and (max-height: 500px) and (orientation: landscape) { @content; }
  }
}

/**
 * Converts pixels to rems
 */
@function toRems($px) {
  @return #{calc($px / 16px)}rem;
}
