.opener__scroller {
  height: 170vh;

  @include screen-from(tablet) {
    height: 170vh;
  }
}

.opener__container {
  opacity: 0;
}

.opener__names,
.opener__date {
  font-family: $california;
  font-size: toRems(192px);
  text-align: center;
  text-transform: uppercase;
}

.opener__date {
  font-size: toRems(144px);
}

.opener__amperand {
  font-size: toRems(112px);
}

.opener__background,
.opener__fixed,
.opener__garden,
.opener__center,
.opener__justin,
.opener__tyler,
.opener__accent-line,
.opener__arrow {
  will-change: transform;
}

