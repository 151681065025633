// Polaroids
.polaroids {
  background-color: $white;
  box-shadow: 0px 0px 20px $black;
  cursor: pointer;
  height: toRems(400px);
  margin: toRems(20px) auto toRems(16px);
  object-fit: cover;
  padding: toRems(20px);
  position: relative;
  touch-action: manipulation;
  width: toRems(326px);
  will-change: transform;

  &:last-child {
    margin-bottom: toRems(32px);
  }

  &.is-clockwise {
    transform: rotate(7deg);
  }

  &.is-counterclockwise {
    transform: rotate(-7deg);
  }

  &__image {
    height: auto;
    margin: 0 auto toRems(16px);
    width: 100%;
  }

  &__figcaption {
    position: relative;
  }

  &__caption {
    color: $tan;
    // TODO FIXME Test 700 font-weight in Firefox
    font-weight: 400;
    font: 700 toRems(22px)/1.2 $california;
    -webkit-font-smoothing: subpixel-antialiased;
    letter-spacing: 0.15rem;

    .hint {
      color: $fire;
      display: none;
      font: 400 toRems(16px)/1 $monospace;

      @include screen-from(tablet) {
        display: block;
      }
    }
  }

  @include screen-from(tablet) {
    margin: toRems(20px) toRems(200px) toRems(50px);
    position: absolute;
    top: toRems(30px);

    &:last-child {
      margin-bottom: toRems(16px);
    }

    &.is-clockwise {
      transform: rotate(15deg);
    }

    &.is-counterclockwise {
      transform: rotate(-15deg);
    }

    + .polaroids {
      top: toRems(60px);

      + .polaroids {
        top: toRems(240px);
      }
    }
  }
}
