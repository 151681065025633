/**
 * Credits section styles
 */
.credits {
  margin: 0 auto;
  padding: 38px 0 19px;
  position: relative;
  width: 100%;
}

.credits__text {
  color: $cream;
  font: 400 13px/1.23 $monospace;
  letter-spacing: 0.15px;
  margin: 0 auto 18px;
  position: relative;
  width: calc(100% - 16px);

  a {
    color: $tan;
  }

  &:first-child {
    border-top: 1px solid $cream;
    padding-top: 41px;
  }

  @include screen-from(mobile-up) {
    width: calc(100% - 32px);
  }

  @include screen-from(mobile-max) {
    width: calc(100% - 48px);
  }

  @include screen-from(tablet) {
    max-width: toRems(680px);
    width: 100%;
  }
}
