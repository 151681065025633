// Colors
$black: #333;
$dark-gray: #4a4a4a;
$white: #fff;

$burnt: #610608;
$sage: #292f15;
$sage-accent: #5d5d44;
$cream: #d4c9b4;
$tan: #986a43;
$alibaster: #f5f4f5;
$fire: #970421;

// Widths
$content: toRems(680px);

// Fonts
$monospace: Monospace, Georgia, serif;
$california: 'California', sans-serif;
