/*
 * Main container for HTML content
 */
.wedding {
  margin: 0 0 toRems(100px);
  padding: 0;
  position: relative;
  width: 100%;

  &.is-loading::after {
    background-color: $sage;
    bottom: 0;
    content: '';
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
  }
}

// Main content article sections
.articles {
  background-color: $cream;
  border: toRems(16px) solid $sage-accent;
  border-radius: 5px;
  box-sizing: content-box;
  display: block;
  margin: 0 auto toRems(100px);
  max-width: toRems(680px);
  position: relative;

  &__header {
    align-items: center;
    border-bottom: 3px solid $sage-accent;
    display: flex;
    justify-content: center;
  }

  &__headline {
    color: $burnt;
    font: 400 toRems(42px)/1 $california;
    letter-spacing: 0.1rem;
    padding-bottom: toRems(20px);
    padding-top: toRems(26px);
    text-align: center;
    text-transform: uppercase;
  }

  &__container,
  &__itinerary-container {
    padding: toRems(16px);
  }

  &__text {
    color: $dark-gray;
    font: 400 toRems(18px)/1.45 $monospace;
    margin: 0 auto 20px;
    max-width: toRems(600px);

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    height: auto;
    margin-bottom: toRems(20px);
    width: 100%;
  }

  a {
    border-bottom: 1px solid $sage;
    color: $burnt;
    text-decoration: none;
    transition: border-bottom 0.25s ease-in-out;

    &:visited {
      border-bottom-color: $tan;
    }

    &:hover {
      border-bottom-color: $alibaster;
    }
  }

  strong {
    text-transform: uppercase;
  }

  @include screen-from(tablet) {
    border-width: toRems(60px);
    display: flex;
    margin-bottom: toRems(50px);

    &__header {
      align-items: flex-start;
      border-bottom: none;
      border-right: 3px solid $sage-accent;
      flex-grow: 0;
      min-width: toRems(100px);
    }

    &__headline {
      font-size: toRems(60px);
      line-height: 1.5;
      padding-bottom: toRems(26px);
      padding-right: toRems(8px);
      padding-top: 0;
      transform: rotate(180deg);
      text-align: initial;
      text-orientation: mixed;
      width: 100%;
      writing-mode: vertical-lr;
    }

    &__container,
    &__itinerary-container {
      padding: toRems(30px);
    }
  }
}

.articles__itinerary-container {
  margin-top: 30px;

  .articles__subheadline,
  .articles__date,
  .articles__olist {
    margin: 0 auto 20px;
    max-width: toRems(600px);
  }

  .articles__subheadline {
    color: $burnt;
    font: 400 toRems(38px)/1 $california;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }

  .articles__date {
    color: $black;
    font: 400 toRems(18px)/1 $monospace;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  .articles__olist {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .articles__item {
    color: $black;
    font: 400 toRems(16px)/1.5 $monospace;
    margin-bottom: 8px;

    strong {
      text-transform: none;
    }
  }

  @include screen-from(tablet) {
    .articles__subheadline,
    .articles__date,
    .articles__olist {
      width: 100%;
    }

    .articles__item {
      margin-bottom: 0;
    }
  }
}

.articles.is-simple {
  display: block;
  padding: toRems(16px) 0;

  .articles__header {
    border-right: none;
    display: block;
    margin: 0 auto toRems(20px);
    max-width: toRems(600px);
    width: auto;
  }

  .articles__headline {
    font-size: toRems(46px);
    margin: 0;
    padding: toRems(20px) toRems(16px) toRems(26px);
    transform: none;
    writing-mode: initial;
  }

  .articles__container {
    padding: 0 toRems(16px);
  }

  sup {
    color: $fire;
  }

  .articles__text:last-child {
    font-size: toRems(13px);
    font-style: italic;
  }

  @include screen-from(tablet) {
    padding: 40px 0;

    .articles__headline,
    .articles__container {
      padding: 0;
    }
  }
}

// Content Container for Feed
.content {
  background-color: $sage;
  margin-top: -90vh;
  position: relative;
  width: 100%;

  &::before {
    background: linear-gradient(transparent 50%, $sage);
    content: '';
    height: toRems(100px);
    left: 0;
    position: absolute;
    top: toRems(-100px);
    width: 100%;
  }

  @include screen-from(tablet) {
    margin-top: 0;
  }
}

// TODO FIXME Need to test the views when a scrollbar is constant

